import React from 'react'
import { Typography } from '~components'

const Error404Page: React.FC = () => {
  return (
    <div className={'h-screen flex flex-col items-center justify-center'}>
      <Typography.Title level={'h1'} className={'text-center'}>
        404
      </Typography.Title>
      <Typography.Text type={'copy'}>
        Ooops!, that page doesn't exist.
      </Typography.Text>
    </div>
  )
}

export default Error404Page
